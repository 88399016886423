import React from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'
import Helmet from 'react-helmet'

import close from 'src/images/icons/close.svg'
import check from 'src/images/icons/check.svg'
import video from 'src/images/icons/video.svg'
import remote from 'src/images/icons/remote.svg'
import caption from 'src/images/icons/checkbook.svg'
import cloud from 'src/images/icons/cloud.svg'

import { MostPopular } from 'src/templates/CalendarProductPage/styles'
import AddToCartButton from '../AddToCartButton'

import { Subheader, ButtonContainer } from '../FrameProductForm/styles'

import {
  Grid,
  SaveInfo,
  PriceAndExplainerContainer,
  ProductSelectionPricePoint,
  FistMonthFree,
  ProductSelectionBox,
  IconContainer,
  CalFeature,
  FeatureTextContainer,
  CalFeatureHeading,
  CalFeatureText,
  ClickableProductSelectionBox,
  ProductSelectionBoxWrapper,
  Icon,
  Disclosure,
} from './styles'
import { FormattedCurrency } from '../../utils/FormattedCurrency'
import { FRAME_PLUS_FEATURES, FRAME_NO_PLAN } from './constants'

const FramePickYourPrice = ({
  selectedProduct,
  setSelectedProduct,
  frameOriginalPrice,
  frameWithPlus,
  frameWithoutPlus,
  quantityVal,
  onAddToCart,
}) => {
  const { t } = useTranslation('common')
  const calPlusFeatureIcons = { video, remote, caption, cloud }
  const calNoPlusFeatureIcons = { check, close }
  const frameWithoutPlusPrice = frameWithoutPlus.variants[0].price
  const frameWithPlusPrice = frameWithPlus.variants[0].price

  return (
    <>
      <Subheader>
        <Trans>Pick your price</Trans>
      </Subheader>
      {selectedProduct === 'standard' ? (
        <>
          <ProductSelectionBoxWrapper>
            <MostPopular>
              <Trans>Most Popular</Trans>
            </MostPopular>
            <ClickableProductSelectionBox
              popular
              style={{ marginTop: -16 }}
              onClick={() => {
                setSelectedProduct('with-plus')
              }}
            >
              <SaveInfo>
                <Trans
                  i18nKey="Save more with Plus Plan"
                  values={{
                    priceSaving: frameWithoutPlusPrice - frameWithPlusPrice,
                  }}
                >
                  Save ${frameWithoutPlus - frameWithPlusPrice} more with Plus Plan
                </Trans>
              </SaveInfo>
              <ProductSelectionPricePoint>
                <strike>${frameOriginalPrice}</strike> ${frameWithPlusPrice}
              </ProductSelectionPricePoint>
            </ClickableProductSelectionBox>
          </ProductSelectionBoxWrapper>
          <ProductSelectionBox>
            <SaveInfo>
              <Trans>Pay full price with no plan</Trans>
            </SaveInfo>
            <ProductSelectionPricePoint color="#444444">
              ${frameWithoutPlusPrice}
            </ProductSelectionPricePoint>
            <FistMonthFree>
              <Trans>Skip the Plus Plan and pay more for your Frame. Without Plus, you get:</Trans>
            </FistMonthFree>
            <Grid>
              {Object.keys(FRAME_NO_PLAN).map((key) => {
                const feature = FRAME_NO_PLAN[key]
                const icon = calNoPlusFeatureIcons[key]
                return (
                  <CalFeature>
                    <IconContainer>
                      <Icon type="image/svg+xml" data={icon} alt={feature.heading}>
                        svg-animation
                      </Icon>
                    </IconContainer>
                    <FeatureTextContainer>
                      <CalFeatureHeading>{feature.heading}</CalFeatureHeading>
                      <CalFeatureText>{feature.text}</CalFeatureText>
                    </FeatureTextContainer>
                  </CalFeature>
                )
              })}
            </Grid>
            <ButtonContainer className="regular-cta">
              <AddToCartButton
                label={t('Add to Cart')}
                handleClick={() => onAddToCart(frameWithoutPlus.variants[0].shopifyId, quantityVal)}
              />
            </ButtonContainer>
          </ProductSelectionBox>
        </>
      ) : (
        <>
          <ProductSelectionBoxWrapper>
            <MostPopular>
              <Trans>Most Popular</Trans>
            </MostPopular>
            <ProductSelectionBox style={{ marginTop: -16 }} popular>
              <SaveInfo>
                <Trans
                  i18nKey="Save more with Plus Plan"
                  values={{
                    priceSaving: frameWithoutPlusPrice - frameWithPlusPrice,
                  }}
                >
                  Save ${frameWithPlus - frameWithPlusPrice} more with Plus Plan
                </Trans>
              </SaveInfo>
              <PriceAndExplainerContainer>
                <ProductSelectionPricePoint>
                  <strike>${frameOriginalPrice}</strike> ${frameWithPlusPrice}
                </ProductSelectionPricePoint>
              </PriceAndExplainerContainer>
              <FistMonthFree>
                Your first month of Skylight Plus is free, then it's $39/year. This includes:
              </FistMonthFree>
              <Grid two>
                {Object.keys(FRAME_PLUS_FEATURES).map((key) => {
                  const feature = FRAME_PLUS_FEATURES[key]
                  const icon = calPlusFeatureIcons[key]
                  return (
                    <CalFeature>
                      <IconContainer>
                        <Icon type="image/svg+xml" data={icon} alt={feature.heading}>
                          svg-animation
                        </Icon>
                      </IconContainer>
                      <FeatureTextContainer>
                        <CalFeatureHeading>{feature.heading}</CalFeatureHeading>
                        <CalFeatureText>{feature.text}</CalFeatureText>
                      </FeatureTextContainer>
                    </CalFeature>
                  )
                })}
              </Grid>
              <ButtonContainer className="regular-cta">
                <AddToCartButton
                  label={t('Add to Cart')}
                  handleClick={() => onAddToCart(frameWithPlus.variants[0].shopifyId, quantityVal)}
                />
              </ButtonContainer>
              <Disclosure>
                *One Plus subscription covers all your Frames! Discount only applies to one Frame.
              </Disclosure>
            </ProductSelectionBox>
          </ProductSelectionBoxWrapper>
          <ClickableProductSelectionBox
            onClick={() => {
              setSelectedProduct('standard')
            }}
          >
            <SaveInfo>
              <Trans>Pay full price with no plan</Trans>
            </SaveInfo>
            <PriceAndExplainerContainer>
              <ProductSelectionPricePoint color="444444">
                <Trans>${frameWithoutPlusPrice}</Trans>
              </ProductSelectionPricePoint>
            </PriceAndExplainerContainer>
          </ClickableProductSelectionBox>
        </>
      )}
    </>
  )
}

FramePickYourPrice.propTypes = {
  selectedProduct: PropTypes.func.isRequired,
  setSelectedProduct: PropTypes.func.isRequired,
  frameOriginalPrice: PropTypes.number.isRequired,
  frameWithPlus: PropTypes.func.isRequired,
  frameWithoutPlus: PropTypes.func.isRequired,
  quantityVal: PropTypes.number,
  onAddToCart: PropTypes.func.isRequired,
}

export default FramePickYourPrice
