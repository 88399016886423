export const FRAME_PLUS_FEATURES = {
  video: { heading: 'Videos', text: 'Send and play videos.' },
  remote: {
    heading: 'Remote setup',
    text: 'Adjust settings from far away.',
  },
  caption: {
    heading: 'Text captions',
    text: 'Add text captions to photos.',
  },
  cloud: { heading: 'Cloud backup', text: 'Your photos backed up forever' },
}

export const FRAME_NO_PLAN = {
  check: { heading: 'Base features', text: 'You can send and view photos free forever.' },
  close: {
    heading: 'No Plus features',
    text: 'You will not be able to upload videos, access remote settings, or use text captions.',
  },
}
